<template>
  <div>
    <navbar></navbar>
    <div class="max-w-7xl mx-auto mt-10 sm:mt-10">
      <!-- This example requires Tailwind CSS v2.0+ -->
      <h2 class="text-2xl font-bold leading-7 text-indigo-800 sm:text-3xl sm:truncate">
        Tüm Öğretmen Listesi
      </h2>
      <div class="flex flex-col mt-5">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Adı
                    </th>
                    <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Kullanıcı Adı
                    </th>
                    <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Şifre
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="item in students" :key="item._id">
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="flex items-center">
                        <div class="flex-shrink-0 h-10 w-10">
                          <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                          </svg> -->
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                          </svg>
                          <!-- <img class="h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=4&amp;w=256&amp;h=256&amp;q=60" alt=""> -->
                        </div>
                        <div class="ml-4">
                          <div class="text-sm text-gray-900">
                            {{item.fullname}}
                          </div>
                          <!-- <div class="text-sm text-gray-500">
                            {{item.username}}
                          </div> -->
                        </div>
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-center">
                      <div class="text-sm text-gray-900">{{item.username}}</div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-center">
                      <div class="text-sm text-gray-900">{{item.password}}</div>
                    </td>
                  </tr>

                  <!-- More rows... -->
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading v-if="ready"></loading>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import services from '@/services/admin';
import navbar from '@/components/navbar/navbar.vue'
import loading from '@/components/loading/loading.vue'


export default {
  name: 'Home',
  data() {
    return {
      students: [],
      ready: false,
    };
  },
  components: {
    navbar,
    loading,
  },
  computed: {
    ...mapGetters(["getRole"]),
  },
  async beforeMount() {
    this.ready = true;
    if (this.getRole !== 'Admin') {
      this.$router.push("/");
    }
    this.getReady();
  },
  methods: {
    async getReady() {
      const data = await services.getTeachersList();
      this.students = data;
      this.ready = false;
    },
  },
}
</script>

<style>

</style>