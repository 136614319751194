import api from './index';

export default {
  async getList() {
    const res = await api().get('admin/list');
    return res.data;
  },
  async getTeachersList() {
    const res = await api().get('admin/teachersList');
    return res.data;
  },
  async getAuth(username, password) {
    const res = await api().post('admin', {
      username,
      password,
    });
    return res.data;
  },
  async addTeacher(student) {
    const res = await api().post('admin/add', {
      fullname: student.fullname,
      username: student.username,
      password: student.password,
    });
    return res.data;
  },
};
