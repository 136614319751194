<template>
  <div>
    <navbar></navbar>

  <div class="mt-10 sm:mt-10">
    <div class="md:grid md:grid-cols-4 md:gap-6">
      <div class="md:col-span-1">
        <!-- <div class="px-4 sm:px-0">
          <h3 class="text-lg font-medium leading-6 text-gray-900">Personal Information</h3>
          <p class="mt-1 text-sm text-gray-600">
            Use a permanent address where you can receive mail.
          </p>
        </div> -->
      </div>
      <div class="mt-5 md:mt-0 md:col-span-2 bg-gray-50">
        <form>
          <div class="shadow overflow-hidden sm:rounded-md">
            <div class="px-4 py-5 bg-white sm:p-6">
              <div class="grid grid-cols-6 gap-6">
                <div class="col-span-12 sm:col-span-12">
                  <label for="field" class="block text-sm font-medium text-red-700">Nasıl Öğrenci Ekleyebilirim?</label>
                  <p class="block text-sm font-medium">
                    Excel üzerinden kopyala yapıştır yaparak öğrenci ekleyebilirsiniz.<br>
                    Öğrenci bilgileri şablona göre hazırlanmalıdır.<br><br>
                    Örnek <a class="text-sm font-medium  text-blue-400" href="https://storage.googleapis.com/aimuploads/%C3%B6rnek.xlsx">şablon dosyasını</a> indir.
                    <!-- Örnek <a class="text-sm font-medium  text-blue-400" href="https://storage.googleapis.com/ueslmscom/eltturkey/UES%20Placement%20Test%20Admin%20How%20to%20Use.mp4" target="_blank">video.</a> -->
                  </p>
                  <label for="field" class="block text-sm font-medium text-gray-700 mt-5">Toplu Öğrenci Ekle</label>
                  <textarea  id="about" name="about" v-model="bulkData" rows="10" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 border px-3 py-2 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="Excel'den kopyaladığınız öğrenci bilgilerini buraya yapıştırın."></textarea>
                </div>
              </div>
            </div>
            <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
              <button @click="save()" type="button" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Kontrol Et / Kaydet
              </button>
            </div>
          </div>
        </form>
        <div v-if="hatalilar" class="shadow overflow-hidden sm:rounded-md mt-5">
          <div class="px-4 py-5 bg-white sm:p-6">
            <div class="grid grid-cols-6 gap-6">
              <div class="col-span-12 sm:col-span-12">
                <label for="field" class="block text-sm font-medium text-red-700">Aşağıdaki Kullanıcılar Eklenemedi (Adı + Kullanıcı Adı)</label>
                <div class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 border px-3 py-2 block w-full sm:text-sm border-gray-300 rounded-md">
                  <template v-for="i in hatalilar">
                    <p :key="i.id">
                      {{i}}
                    </p>
                  </template>
                </div>
                <!-- <textarea disabled  id="about" name="about" v-model="hatalilar" rows="10" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 border px-3 py-2 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="Yapıştır"></textarea> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <loading v-if="ready"></loading>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Swal from 'sweetalert2';
import services from '@/services/teacher';
import navbar from '@/components/navbar/navbar.vue'
import loading from '@/components/loading/loading.vue'


export default {
  data() {
    return {
      bulkData: null,
      ready: false,
      hatalilar: null,
    };
  },
  computed: {
    ...mapGetters(['getRole', 'getTeacherId']),
  },
  components: {
    navbar,
    loading,
  },
  beforeMount() {
    if (this.getRole !== 'Teacher') {
      this.$router.push("/");
    }
  },
  methods: {
    async save() {
      this.ready = true;
      if (this.bulkData === null || this.bulkData === '')  {
        this.ready = false;
        return;
      }
      const trimData = (this.bulkData.trim());
      const rows = trimData.split("\n");
      const errorsData = [];
      const successData = [];
      for (let y in rows) {
        let cells = rows[y].split("\t");
        if (cells.length < 4) {
          this.ready = false;
          Swal.fire(
            'Hata!',
            'Geçersiz kullanıcı ekleme metni.',
            'error'
          );
          return;
        }
        const level = (cells[3].trim()).toLowerCase();
        const student = {
          fullname: cells[0].trim(),
          username: cells[1].trim(),
          password: cells[2].trim(),
          level: (level.charAt(0).toUpperCase()) + level.slice(1),
          teacherId:  this.getTeacherId,
        }
        if (student.username === '' || student.password === '' || student.fullname.length < 5 || student.level === '' || !student.teacherId) {
          errorsData.push(cells[1].trim());
        } else {
          const data = await services.addStudent(student);
          if (data._id) {
            successData.push(cells[1].trim());
          } else {
            const err = `${cells[0].trim()} + ${cells[1].trim()}`
            errorsData.push(err);
          }
        }
      }
      console.log('successData', successData);
      this.bulkData = null;
      if (errorsData.length > 0) {
        this.hatalilar = errorsData;
      } else {
        this.hatalilar = null;
      }
      this.ready = false;
    },
  },
}
</script>

<style>

</style>