import Vue from 'vue';
import Router from 'vue-router';
import store from "./store/index"
// import tools from '@/tools';

import loginPage from '@/components/login/login.vue';
import homePage from '@/components/home/home.vue';
import studentPage from '@/components/student/student.vue';
import bulkStudentPage from '@/components/bulkStudent/bulkStudent.vue';
import teacherPage from '@/components/teacher/teacher.vue';
import teachersListPage from '@/components/teachersList/teachersList.vue';
import adminHomePage from '@/components/adminHome/adminHome.vue';


Vue.use(Router);

const router = new Router({
  // mode: "history",
  routes: [
    {
      name: 'login-page',
      path: '/login',
      component: loginPage,
    },
    {
      name: 'home',
      path: '/',
      component: homePage,
    },
    {
      name: 'student',
      path: '/addStudent',
      component: studentPage,
    },
    {
      name: 'studentBulk',
      path: '/addStudentBulk',
      component: bulkStudentPage,
    },
    {
      name: 'admin-home',
      path: '/adminHome',
      component: adminHomePage,
    },
    {
      name: 'teacher',
      path: '/addTeacher',
      component: teacherPage,
    },
    {
      name: 'teacherList',
      path: '/teacherList',
      component: teachersListPage,
    },
  ],
});

export default router;

router.beforeEach((to, from, next) => {
  const auth = store.getters.isAuthenticated;
  if (auth === null && !(to.name === 'login-page')) {
    router.push('/login');
    next();
  }
  next();
});