import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default {
  state: {
      isAuthentication: null,
      role: null,
      expireTime: null,
      teacherId: null,
  },
  getters: {
      isAuthenticated(state) {
          return state.isAuthentication
      },
      getRole(state) {
          return state.role;
      },
      getExpireTime(state) {
          return state.expireTime
      },
      getTeacherId(state) {
        return state.teacherId
    }
  },
  mutations: {
      setAuthenticate(state, payload) {
          state.isAuthentication = payload
      },
      setRole(state, payload) {
          state.role = payload
      },
      setExpireTime(state, payload) {
          state.expireTime = payload
      },
      setTeacherId(state, payload) {
        state.teacherId = payload
    }
  },
  actions: {
      initAuth({state, dispatch}) {
          let time = Date.now();
          if (time > state.expireTime) {
              dispatch("logout");
          }
      },
      logout({commit}) {
          commit("setAuthenticate",null);
          commit("setRole",null);
          commit("setExpireTime",null);
          commit("setTeacherId",null);
      }
  }
}
