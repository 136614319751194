import api from './index';

export default {
  async getList(teacherId) {
    const res = await api().post('teacher/list', {
      teacherId,
    });
    return res.data;
  },
  async getAuth(username, password) {
    const res = await api().post('teacher', {
      username,
      password,
    });
    return res.data;
  },
  async addStudent(student) {
    const res = await api().post('teacher/add', {
      fullname: student.fullname,
      username: student.username,
      password: student.password,
      teacherId: student.teacherId,
      level: student.level,
    });
    return res.data;
  },
};
