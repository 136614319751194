<template>
  <div>
    <navbar></navbar>

  <div class="mt-10 sm:mt-10">
    <div class="md:grid md:grid-cols-4 md:gap-6">
      <div class="md:col-span-1">
        <!-- <div class="px-4 sm:px-0">
          <h3 class="text-lg font-medium leading-6 text-gray-900">Personal Information</h3>
          <p class="mt-1 text-sm text-gray-600">
            Use a permanent address where you can receive mail.
          </p>
        </div> -->
      </div>
      <div class="mt-5 md:mt-0 md:col-span-2 bg-gray-50">
        <form>
          <div class="shadow overflow-hidden sm:rounded-md">
            <div class="px-4 py-5 bg-white sm:p-6">
              <div class="grid grid-cols-6 gap-6">
                <div class="col-span-6 sm:col-span-3">
                  <label for="first_name" class="block text-sm font-medium text-gray-700">Adı</label>
                  <input v-model="firstname" type="text" name="first_name" id="first_name" autocomplete="given-name" class="mt-1 border px-3 py-2 border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm block w-full">
                </div>

                <div class="col-span-6 sm:col-span-3">
                  <label for="last_name" class="block text-sm font-medium text-gray-700">Soyadı</label>
                  <input v-model="lastname" type="text" name="last_name" id="last_name" autocomplete="family-name" class="mt-1 border px-3 py-2 border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm block w-full">
                </div>

                <div class="col-span-6 sm:col-span-3">
                  <label for="username" class="block text-sm font-medium text-gray-700">Kullanıcı Adı</label>
                  <input v-model="username" type="text" name="username" id="username" autocomplete="username" class="mt-1 border px-3 py-2 border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm block w-full">
                </div>

                <div class="col-span-6 sm:col-span-3">
                  <label for="password" class="block text-sm font-medium text-gray-700">Şifre</label>
                  <input v-model="password" type="password" name="password" id="password" autocomplete="password" class="mt-1 border px-3 py-2 border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm block w-full">
                </div>
              </div>
            </div>
            <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
              <button @click="save()" type="button" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Kaydet
              </button>
            </div>
<!-- 
            <div>
              <textarea  id="about" name="about" v-model="bulkData" rows="10" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 border px-3 py-2 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="Excel'den kopyaladığınız öğrenci bilgilerini buraya yapıştırın."></textarea>
            </div>
            <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
              <button @click="bulkSave()" type="button" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Kontrol Et / Kaydet
              </button>
            </div> -->
          </div>
        </form>
      </div>
    </div>
  </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Swal from 'sweetalert2';
import services from '@/services/admin';
import navbar from '@/components/navbar/navbar.vue'

export default {
  data() {
    return {
      students: [],
      firstname: '',
      lastname: '',
      username: '',
      password: '',
      level: null,
      bulkData: '',
    };
  },
  computed: {
    ...mapGetters(['getRole']),
  },
  components: {
    navbar,
  },
  beforeMount() {
    if (this.getRole !== 'Admin') {
      this.$router.push("/");
    }
  },
  methods: {
    async save() {
      const teacher = {
        username: this.username,
        password: this.password,
        fullname: `${this.firstname} ${this.lastname}`,
      };
      if (teacher.username === '' || teacher.password === '' || teacher.fullname.length < 5) {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Beklenmedik hata oluştu.',
          showConfirmButton: false,
          timer: 1500
        });
        return;
      }
      const data = await services.addTeacher(teacher);
      if (data._id) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Öğretmen Oluşturuldu.',
          showConfirmButton: false,
          timer: 1500
        });
        this.username = '';
        this.password = '';
        this.firstname = '';
        this.lastname = '';
      } else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: data.message,
          showConfirmButton: false,
          timer: 1500
        });
      }
    },
    async bulkSave() {
      const trimData = (this.bulkData.trim());
      const rows = trimData.split("\n");
      const errorsData = [];
      const successData = [];
      for (let y in rows) {
        let cells = rows[y].split("\t");
        const teacher = {
          username: `fnl${cells[0].trim()}`,
          password: Number(cells[0].trim()),
          fullname: cells[1].trim(),
        };
        if (teacher.username === '' || teacher.password === '' || teacher.fullname.length < 5) {
          errorsData.push(cells[1].trim());
        } else {
          const data = await services.addTeacher(teacher);
          if (data._id) {
            successData.push(cells[1].trim());
          } else {
            const err = `${cells[0].trim()} + ${cells[1].trim()}`
            errorsData.push(err);
          }
        }
      }
    }
  },
}
</script>

<style>

</style>