<template>
  <div>
    <navbar></navbar>
    <div class="max-w-7xl mx-auto mt-10 sm:mt-10">
      <!-- This example requires Tailwind CSS v2.0+ -->
      <h2 class="text-2xl font-bold leading-7 text-indigo-800 sm:text-3xl sm:truncate">
        Tüm Öğrenci Listesi
      </h2>
      <div class="flex flex-col mt-5">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Adı / Kullanıcı Adı
                    </th>
                    <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Şifre
                    </th>
                    <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Seviye
                    </th>
                    <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Sonuç
                    </th>
                    <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Vocabulary
                    </th>
                    <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Grammar
                    </th>
                    <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Meaning in Context
                    </th>
                    <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Listening
                    </th>
                    <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Average
                    </th>
                    <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Durum
                    </th>
                    <th scope="col" class="relative px-6 py-3">
                      <span class="sr-only">Reset</span>
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200" v-for="(item, i) in students" :key="item._id">
                  <tr>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="flex items-center">
                        <div class="ml-4">
                          <div class="text-sm text-gray-900">
                            {{item.fullname}}
                          </div>
                          <div class="text-sm text-gray-900">
                            {{item.username}}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-center">
                      <div class="text-sm text-gray-900">{{item.password}}</div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-center">
                      <div class="text-sm text-gray-900">{{item.level}}</div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap" @click="runAccordion(i, !item.quiz ? 0 : item.quiz.length > 0 ? item.quiz.length : 0 )">
                      <div class="flex items-center">
                        <div class="ml-4">
                          <div class="text-sm text-gray-900">
                            {{!item.quiz ? '-' : item.quiz.length > 0 ? maxResult(item.quiz, 'level') : '-'}}
                          </div>
                        </div>
                        <div v-if="item.quiz && item.quiz.length > 1" class="flex-shrink-0 h-5 w-5" >
                          <svg :id="`down${i}`" class="" style="cursor:pointer" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 17l-4 4m0 0l-4-4m4 4V3" />
                          </svg>
                          <svg :id="`up${i}`" class="hidden" style="cursor:pointer" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7l4-4m0 0l4 4m-4-4v18" />
                          </svg>
                        </div>
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-center">
                      <div class="text-sm text-gray-900">{{!item.quiz ? 0 : item.quiz.length > 0 ? Number(maxResult(item.quiz, 'Vocabulary')).toFixed(2) : 0}}%</div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-center">
                      <div class="text-sm text-gray-900">{{!item.quiz ? 0 : item.quiz.length > 0 ? Number(maxResult(item.quiz, 'Grammar')).toFixed(2) : 0}}%</div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-center">
                      <div class="text-sm text-gray-900">{{!item.quiz ? 0 : item.quiz.length > 0 ? Number(maxResult(item.quiz, 'Meaning')).toFixed(2)  : 0}}%</div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-center">
                      <div class="text-sm text-gray-900">{{!item.quiz ? 0 : item.quiz.length > 0 ? Number(maxResult(item.quiz, 'Listening')).toFixed(2)  : 0}}%</div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-center">
                      <div class="text-sm text-gray-900">{{!item.quiz ? 0 : item.quiz.length > 0 ? Math.floor(maxResult(item.quiz, 'success')) : 0}}%</div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-center">
                      <span v-if="!item.status" class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                        Aktif
                      </span>
                      <span v-else class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                        Aktif
                      </span>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <a @click="resetStudent(item.username, item.password)" class="text-indigo-600 hover:text-indigo-900 cursor-pointer">Reset</a>
                    </td>
                  </tr>
                  <template v-for="element in item.quiz">
                    <tr :class="`accordion${i} hidden`" :key="element.id">
                      <td class="px-6 py-4 whitespace-nowrap"></td>
                      <td class="px-6 py-4 whitespace-nowrap"></td>
                      <td class="px-6 py-4 whitespace-nowrap"></td>
                      <td class="px-6 py-4 whitespace-nowrap text-center">
                        <div class="text-sm text-gray-900">{{element.level}}</div>
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-center">
                        <!-- <div class="text-sm text-gray-900">{{String(element.Vocabulary).length > 6 ? element.Vocabulary.toFixed(2): element.Vocabulary}}%</div> -->
                        <div class="text-sm text-gray-900">{{slicePoint(element.Vocabulary)}}%</div>
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-center">
                        <div class="text-sm text-gray-900">{{slicePoint(element.Grammar)}}%</div>
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-center">
                        <div class="text-sm text-gray-900">{{slicePoint(element.Meaning)}}%</div>
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-center">
                        <div class="text-sm text-gray-900">{{slicePoint(element.Listening)}}%</div>
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-center">
                        <div class="text-sm text-gray-900">{{Math.floor(element.success) !== null ? Math.floor(element.success) : 0}}%</div>
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-center"></td>
                      <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"></td>
                    </tr>
                  </template>

                  <!-- More rows... -->
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading v-if="ready"></loading>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Swal from 'sweetalert2';
import services from '@/services/admin';
import studentServices from '@/services/student';
import navbar from '@/components/navbar/navbar.vue'
import loading from '@/components/loading/loading.vue'


export default {
  name: 'Home',
  data() {
    return {
      students: [],
      ready: false,
    };
  },
  components: {
    navbar,
    loading,
  },
  computed: {
    ...mapGetters(["getRole"]),
  },
  async beforeMount() {
    this.ready = true;
    if (this.getRole !== 'Admin') {
      this.$router.push("/");
      return;
    }
    this.getReady();
  },
  methods: {
    async getReady() {
      const data = await services.getList();
      this.students = data;
      this.ready = false;
    },
    async resetStudent(username, password) {
      Swal.fire({
        title: 'Emin misiniz?',
        text: "Resetlenen kullanıcının bilgileri geri alınamaz!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Vazgeç',
        confirmButtonText: 'Evet, resetle!'
      }).then(async(result) => {
        if (result.isConfirmed) {
          const res = await studentServices.resetStudent(username, password);
          if (res.success === false) {
            Swal.fire(
              'Hata!',
              'Beklenmedik hata oluştu.',
              'error'
            )
          } else {
            this.getReady();
            Swal.fire(
              'Resetlendi!',
              'Kullanıcı resetleme işlemi başarıyla gerçekleşti.',
              'success'
            );
          }
        }
      });
    },
    maxResult(levelsAndPercents, type) {
      let currentLevel = null;
      if (levelsAndPercents.length === 1) {
        currentLevel = levelsAndPercents[0];
      } else {
        for (let i = levelsAndPercents.length - 1; i >= 0; i -= 1) {
          const level = levelsAndPercents[i];
          if (level.success > 70) {
            currentLevel = level;
            break;
          } else {
            currentLevel = levelsAndPercents[i - 1];
            break;
          }
        }
      }
      return currentLevel[type];
    },
    runAccordion(i, item) {
      if (item > 1) {
        let element = document.querySelectorAll(`.accordion${i}`)
        for (let index = 0; index < item; index++) {  
          if (element[index].classList[1] === 'hidden') {
            element[index].classList.remove("hidden");
            let downsvg = document.getElementById(`down${i}`);
            downsvg.classList.add("hidden");
            let upsvg = document.getElementById(`up${i}`);
            upsvg.classList.remove("hidden");
          } else {
            element[index].classList.add("hidden");
            let downsvg = document.getElementById(`down${i}`);
            downsvg.classList.remove("hidden");
            let upsvg = document.getElementById(`up${i}`);
            upsvg.classList.add("hidden");
          }
        }
      }
    },
    slicePoint(point) {
      if (point === null) {
        return 0;
      } else {
        const currentPoint = String(point).split('.');
        const totalPoint = currentPoint[1] ? `${currentPoint[0]}.${currentPoint[1].slice(0, 2)}` : point;
        return totalPoint;
      }
    },
  },
}
</script>

<style>

</style>