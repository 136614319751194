<template>
<div>
  <div class="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-md w-full space-y-8">
      <div>
        <img class="mx-auto h-40 w-auto" src="../../assets/uesdigital.png">
        <!-- <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Sign in to your account
        </h2>
        <p class="mt-2 text-center text-sm text-gray-600">
          Or
          <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">
            start your 14-day free trial
          </a>
        </p> -->
      </div>
      <form class="">
        <!-- <input type="hidden" name="remember" value="true"> -->
        <div class="rounded-md shadow-sm -space-y-px mb-6">
          <div>
            <label for="username" class="sr-only">Kullanıcı Adı</label>
            <input v-model="username" name="username" type="text" autocomplete="username" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Kullanıcı Adı">
          </div>
          <div>
            <label for="password" class="sr-only">Şifre</label>
            <input v-model="password" name="password" type="password" autocomplete="current-password" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Şifre">
          </div>
        </div>

        <div @click.prevent="login()">
          <button class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              <!-- Heroicon name: lock-closed -->
              <svg class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
              </svg>
            </span>
            Giriş Yap
          </button>
        </div>
      </form>
    </div>
    <loading v-if="ready"></loading>
  </div>
</div>
</template>

<script>
import Swal from 'sweetalert2';
import services from '@/services/teacher';
import adminServices from '@/services/admin';
import loading from '@/components/loading/loading.vue'

export default {
  data() {
    return {
      username: null,
      password: null,
      ready: false,
    };
  },
  components: {
    loading,
  },
  computed: {
  },
  async beforeMount() {
    this.autoLogin();
  },
  methods: {
    async autoLogin() {
      const code = this.$route.query.code;
      if (code) {
        this.username = `fnl${atob(code)}`;
        this.password = atob(code);
        this.login();
      }
    },
    async login() {
      this.ready = true;
      const admin = await adminServices.getAuth(this.username, this.password);
      if (admin._id) {
        this.setLoginState("Admin", admin._id);
        return;
      }
      const user = await services.getAuth(this.username, this.password);
      if (user._id) {
        this.setLoginState("Teacher", user._id);
        return;
      }
      Swal.fire(
        'Hata!',
        'Kullanıcı Adı veya Şifre yanlış',
        'error'
      )
      this.ready = false;
    },
    setLoginState(role, id) {
      this.$store.commit("setAuthenticate", true);
      this.$store.commit("setRole", role);
      this.$store.commit("setTeacherId", id);
      this.$store.commit("setExpireTime", Date.now() + 3600000);
      if (role === "Admin") {
        this.$router.push("/adminHome");
      } else {
        this.$router.push("/");
      }
    }
  }
}
</script>

<style>

</style>