import axios from 'axios';
// import tools from '@/tools';

const instance = axios.create();

instance.interceptors.request.use((config) => {
  // eslint-disable-next-line no-param-reassign
  // config.baseURL = 'http://localhost:8028/';
  config.baseURL = 'https://report-placement-api.appspot.com/';
  return config;
});

instance.interceptors.response.use(res => res, err => err);
export default () => instance;
